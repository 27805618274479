<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        v-if="approvement == 0"
        :small="small"
        class="white--text"
        color="grey"
        v-bind="attrs"
        v-on="on"
      >
        <template><div>รอดำเนินการ</div></template>
        <v-icon small right>mdi-chevron-down</v-icon>
      </v-chip>
      <v-chip
        v-else-if="approvement == 1"
        :small="small"
        class="white--text"
        color="success"
        v-bind="attrs"
        v-on="on"
      >
        <template><div>อนุมัติ</div></template>
        <v-icon small right>mdi-chevron-down</v-icon>
      </v-chip>
      <v-chip
        v-else-if="approvement == 2"
        :small="small"
        class="white--text"
        color="error"
        v-bind="attrs"
        v-on="on"
      >
        <template><div>ไม่อนุมัติ</div></template>
        <v-icon small right>mdi-chevron-down</v-icon>
      </v-chip>
    </template>

    <v-list v-if="approvement == 0 && permission">
      <template v-for="item in list">
        <v-list-item
          :key="item.value"
          link
          dense
          v-if="item.value != approvement"
          @click="onSelected(item.value)"          
        >
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>


<script>
export default {
  model: {
    prop: 'approvement',
    event: 'change',
  },
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
    approvement: {
      type: [String, Number],
      default: 'active',
    },
    small: {
      type: Boolean,
      default: false,
    },
    permission: {
      type: Boolean,
      default: false,
    },
    approvementId: {
      required: false,
      type: [String, Number],
      default: 0,
    },
  },

  data() {
    return {
      list: [
        { text: 'อนุมัติ', value: 10, color: '' },
        { text: 'ไม่อนุมัติ', value: 11, color: '' },
      ],
    };
  },
  async created() {
    if (this.approvementId != 0) this.list = [];
  },
  methods: {
    onSelected(status) {
      if (status == 10) {
        this.approveDocument(true);
      } else if (status == 11) {        
        this.approveDocument(false);
      }
    },
    async approveDocument(approvement) {
      let body = {
        token: this.$jwt.sign({ log_document_id: this.id, approvement:approvement }, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios.post(`${this.$baseUrl}/document/edit_data_approvement`, body)
        .then(async (res) => {
          console.log(res.result);
          this.$emit('success');
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
    },
  },

  computed: {
    color() {
      let color = 'success';
      this.list.forEach((item) => {
        if (this.approvement == item.value) color = item.color;
      });
      return color;
    },
  },
};
</script>
